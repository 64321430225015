import { navigate } from "gatsby";

export const onRouteUpdate = ({ location: { pathname, origin }, prevLocation, loadPageSync }) => {
    // Redirect /us/blog/* paths to /blog/* on preview servers (or localhost).
    if (
        (origin?.includes("preview.onrender.com") || origin?.includes("localhost")) &&
        pathname?.startsWith("/us/blog")
    ) {
        navigate(pathname.slice(3));
    }

    // If a resource path (with no prevLocation) 404s on preview it's likely that there's a missed redirect to the asset in cloudinary.
    // If that's the case, navigate to the current path on the live site, which will then redirect to the cloudinary asset.
    if (
        (origin?.includes("preview.onrender.com") || origin?.includes("localhost")) &&
        pathname?.startsWith("/us/resource-library") &&
        !prevLocation
    ) {
        const currentPage = loadPageSync(pathname);
        const env = origin?.includes("qlik-master-preview") ? "main" : "qa";

        if (!currentPage || currentPage?.page?.path?.includes("404")) {
            window.location.href = `https://qcd-335-blog-preview-redirect--qlik-www.netlify.app/.netlify/functions/ungatedResourcePreviewRedirect?env=${env}&slug=${pathname}`;
        }
    }
};
